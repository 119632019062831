<template>
  <StringerBasic />
</template>

<script>

import StringerBasic from './components/StringerBasic.vue'

export default {
  name: 'App',
  components: {
    StringerBasic
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;500;600&display=swap');

body {
  background: #000;
}

#app {
  font-family: 'Inconsolata', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  color: #ccc;
}

::selection {
  background: #666; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #666; /* Gecko Browsers */
}

</style>
