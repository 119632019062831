<template>
  <pre class="ruler top">12345678901234567890123456789012345678901234567890123456789</pre>
  <pre class="preview" v-html="content"></pre>
  <v-ace-editor
    class="input"
    v-model:value="rawContent"
    @init="editorInit"
    lang="xml"
    printMargin="10"
    theme="tomorrow_night_bright"
     />
  <pre class="ruler bottom">123456789012345678901234567890123456789xx987654321098765432109876543210987654321</pre>
</template>

<script>

    import ace from 'ace-builds'
  import { VAceEditor } from 'vue3-ace-editor'

  import 'ace-builds/src-min-noconflict/mode-xml'
  import 'ace-builds/src-min-noconflict/theme-tomorrow_night_bright'

ace.config.set(
        "behavioursEnabled", 
        false)

  ace.config.set("printMargin", 0)
  ace.config.set('wrap', false)
  ace.config.set('fontSize', '14px')
  ace.config.set('showGutter', false)
  

  const getDefaultContent = () => {
    let savedContent = window.localStorage.getItem('content')

    if(savedContent && typeof(savedContent) === 'string' && savedContent.length > 0) {
      return savedContent
    } 

    let defaultContent = '"My grandfather came to this country with nothing but:\\n\\n<aca> <<add>worn on torso<aca>>     {x <faa>t<fcc>a<fdd>t<bbb>t<fdd>er<ccc>e<fdd>d sh<ccc>i<fcc>r<faa>t{x\\n<aca> <<add>worn on legs<aca>>      {x <eff>sturdy{x <bbf>p<ccf>a<dee>ir <cdd>of <dee>pan<ccf>t<bbf>s{x\\n<aca> <<add>worn on feet<aca>>      {x <bbb>solid{x <bbb>p<ccc>a<ddd>ir <eee>of <ddd>sho<ccc>e<bbb>s{x\\n\\n<aca> <<add>held in right hand<aca>>{x <ddd>large{x <fec>s<fdc>ui<fcc>t<fdc>cas<fec>e{x <ccc>full of{x <fea>[G<ffa>O<ffd>LD <fea>B<ffd>A<ffa>R<fea>S<ffa>]{x    \\n<aca> <<add>held in left hand<aca>> {x <ddd>large{x <fec>s<fdc>ui<fcc>t<fdc>cas<fec>e{x <ccc>full of{x <fea>[G<ffa>O<ffd>LD <fea>B<ffd>A<ffa>R<fea>S<ffa>]{x"'
    return JSON.parse(defaultContent)
  }

  const specialColors = {
    g00: [0, 0, 0],
    g01: [11, 11, 11],
    g02: [22, 22, 22],
    g03: [33, 33, 33],
    g04: [44, 44, 44],
    g05: [55, 55, 55],
    g06: [67, 67, 67],
    g07: [78, 78, 78],
    g08: [89, 89, 89],
    g09: [100, 100, 100],
    g10: [111, 111, 111],
    g11: [122, 122, 122],
    g12: [133, 133, 133],
    g13: [144, 144, 144],
    g14: [155, 155, 155],
    g15: [166, 166, 166],
    g16: [177, 177, 177],
    g17: [188, 188, 188],
    g18: [200, 200, 200],
    g19: [211, 211, 211],
    g20: [222, 222, 222],
    g21: [233, 233, 233],
    g22: [244, 244, 244],
    g23: [255, 255, 255],
  }

  const toRGB = (color) => {
    if(specialColors[color]) {
      return specialColors[color]
    }

    return color.split('').map(c => {
      return {
        a: 0,
        b: 51,
        c: 102,
        d: 153,
        e: 204,
        f: 255,
      }[c]
    })
  }

  const toHTML = (s) => {
    const parts = s.replace(/({x)/gi, '<eee>').split(/<([a-f]{3}|g[0-2][0-9])>/g)
    
    let o = parts.shift()

    while(parts.length) {
      let color = toRGB(parts.shift())
      let text = parts.shift()

      o += `<span style="color: rgba(${color[0]}, ${color[1]}, ${color[2]}, 1.0);">${text}</span>`
    }

    return o
  }

  export default {
    name: 'App',
     data: () => ({
      rawContent : getDefaultContent(),
    }),
    components: {
      VAceEditor,
    },
    computed: {
      content: {
        set: function(v) {
          this.rawContent = v
        },
        get: function() {
          const c = this.rawContent
          window.localStorage.setItem('content', this.rawContent)
          return toHTML(c)
        }
      } 
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .preview, .ruler {
    font-size: 14px;
  }

  .preview {
    background: #000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 50vmax;
    box-sizing: border-box;
    padding: 20px 10px 20px 3px;
    margin: 0;
    z-index: 1;
  }

  .input {
    font-family: 'Inconsolata', monospace;
    position: fixed;
    top: 50vh;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background: #222;
    border: 0;
    color: #ccc;
    box-sizing: border-box;
    padding: 10px 20px;
    z-index: 1;
    font-size: 14px;
    height: 50vh;
  }

  .ruler {
    width: 100vw;
    color: #666;
    height: 1.2em;
    position: fixed;
    left: 0;
    margin: 0;
    background: rgba(255,255,255,0.1);
    line-height: 1.4em;
    padding-left: 3px;
    z-index: 1000;
  }

  .ruler.bottom {
    bottom: 50%;
  }

  .ruler.top {
    top: 0;
  }

  .ace_print-margin-layer {
    display: none;
  }
</style>
